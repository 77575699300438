import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dialogs-popups-and-modals"
    }}>{`Dialogs, Popups, and Modals`}</h1>
    <p>{`Workday has several kinds of popup windows or notifications, including dialogs, modals, popups,
toasts, and more. Here are some general guidelines for the text contained within these elements:`}</p>
    <ul>
      <li parentName="ul">{`Phrase confirmations as yes or no questions.`}</li>
      <li parentName="ul">{`In the title of the dialog, be clear about what will happen if the user continues. (“Delete time
off request?”)`}</li>
      <li parentName="ul">{`Supplemental text should provide more information, not just reword the title.`}</li>
      <li parentName="ul">{`Tonally, your language should be approachable and clear. Try reading your message out loud, and if
it sounds unnatural to say, try rewriting.`}</li>
      <li parentName="ul">{`Use title case for dialog titles and buttons.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul"><strong parentName="li">{`Delete Page?`}</strong><br parentName="li"></br>
          {`If you delete this page, the information will not be saved.`}<br parentName="li"></br>
          {`Delete Cancel`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul"><strong parentName="li">{`Delete Page?`}</strong><br parentName="li"></br>
          {`Are you sure you want to delete this process?`}<br parentName="li"></br>
          {`OK Cancel`}</li>
      </ul>
    </Dont>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      